<template>
  <div class="no-login-repair-contaner">
    <Header></Header>
    <Nav></Nav>
    <!-- 未登录内容 start -->
    <!-- 我的设备 start -->
    <el-row class="repair-box">
      <el-row class="contaner">
        <el-row class="repair">
          <p class="title">报修</p>
          <!-- 表单部分 -->
          <el-row class="form-box">
            <el-form
              label-width="120px"
              :model="ruleForm"
              :status-icon="false"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
              :hide-required-asterisk="false"
            >
              <el-form-item label="联系人" required prop="name">
                <el-input
                  type="text"
                  v-model="ruleForm.name"
                  maxlength="10"
                  placeholder="请输入您的姓名"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" required prop="phone">
                <el-input
                  type="phone"
                  v-model="ruleForm.phone"
                  maxlength="11"
                  placeholder="请输入联系电话"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="问题描述" required prop="problem">
                <el-input
                  type="textarea"
                  maxlength="300"
                  v-model="ruleForm.problem"
                  placeholder="请输入您的特殊备注内容"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item class="btn">
                <button
                  v-for="(item, index) in tagData"
                  :key="index"
                  :class="{ active: activeTag.includes(index) }"
                  @click.prevent="tagHandle(index, item)"
                >
                  {{ item.tagName }}
                </button>
              </el-form-item>
              <el-form-item class="upload" label="图片 / 视频上传">
                <!-- https://jsonplaceholder.typicode.com/posts/ -->
                <el-upload
                  :action="objData.host"
                  :data="objData"
                  multiple
                  :limit="3"
                  list-type="picture-card"
                  :before-upload="beforeUploadFun"
                  :on-progress="progressFun"
                  :on-remove="handleRemove"
                  :on-success="videoSuccess"
                  :on-change="handleChange"
                  :file-list="fileList"
                >
                  <!-- <i class="el-icon-plus" v-if="showAddSource"></i> -->
                  <div
                    slot="trigger"
                    class="add-upload-box"
                    v-if="showAddSource"
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                  <!-- <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  > -->
                </el-upload>
                <el-dialog
                  :visible.sync="videodialogVisible"
                  :append-to-body="true"
                >
                  <video
                    id="upvideo"
                    width="100%"
                    autoplay
                    height="500"
                    :src="dialogImageUrl"
                    controls
                  ></video>
                </el-dialog>
                <!-- <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="ruleForm.dialogImageUrl" alt="" />
                </el-dialog> -->
              </el-form-item>
              <el-form-item>
                <el-button class="login" @click="submitForm('ruleForm')"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
        </el-row>
      </el-row>
    </el-row>
    <!-- 我的设备 end -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
let flag = true; //防止重复点击

export default {
  name: "YoufujichuangNoLoginRepair",
  components: {
    Header,
    Nav,
    Footer,
  },
  data() {
    // 校验姓名
    // var validateName = (rule, value, callback) => {
    //   console.log(/^1[3-9]\d{9}$/.test(this.ruleForm.phone));
    //   if (value === "") {
    //     callback(new Error("请输入姓名"));
    //   }
    //   callback();
    // };

    // 校验手机号
    var validatePhone = (rule, value, callback) => {
      // console.log(/^1[3-9]\d{9}$/.test(this.ruleForm.phone));
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3-9]\d{9}$/.test(this.ruleForm.phone)) {
          callback(new Error("手机号格式不正确"));
        }
      }
      callback();
    };

    // // 校验问题描述输入框
    // var validateProblem = (rule, value, callback) => {
    //   console.log(/^1[3-9]\d{9}$/.test(this.ruleForm.phone));
    //   if (value === "") {
    //     callback(new Error("请输入备注内容"));
    //   }
    //   callback();
    // };

    return {
      dialogVisible: false,
      // active: 1, //特殊备注内容按钮选择激活项

      // 表单数据绑定
      ruleForm: {
        name: "",
        phone: "",
        problem: "",
      },

      // 表单校验规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: ["blur", "change"],
          },
        ],
        phone: [{ validator: validatePhone, trigger: ["blur", "change"] }],
        problem: [
          {
            required: true,
            message: "请输入问题描述内容",
            trigger: ["blur", "change"],
          },
        ],
      },

      // 问题描述标签
      tagData: [], //问题描述数据
      activeTag: [],
      activeIds: [],

      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏
    };
  },

  created() {
    // 标签-列表查询
    this.tagList();

    //根据分组查询系统配置
    this.getOssConfig();
  },

  methods: {
    // 图片上传方法
    videoSuccess(res, file, fileList) {
      this.findvideocover(file.url, file);
    },

    //截取视频第一帧作为播放前默认图片
    findvideocover(url, file) {
      // const  video = document.getElementById("myvideo"); // 获取视频对象
      const video = document.createElement("video"); // 也可以自己创建video
      // video.innerHTML = "111";
      video.src = url; // url地址 url跟 视频流是一样的

      // var canvas = document.getElementById('mycanvas') // 获取 canvas 对象
      var canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        console.log(video.clientWidth, video.clientHeight);
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        this.videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        file.url = this.videoFirstimgsrc;
        // base64转成bolb文件
        const fileBolb = this.base64toFile(
          this.videoFirstimgsrc,
          this.getFileName(file)
        );
        // 把首图上传生成云上地址
        this.getFirstPngUrl(fileBolb);
        video.remove();
        canvas.remove();
      };
    },

    base64toFile(baseUrl, filename = "file") {
      let arr = baseUrl.split(",");
      let type = arr[0].match(/:(.*?);/)[1]; // 解锁图片类型
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let bufferArray = new Uint8Array(n);
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n);
      }
      return new File([bufferArray], filename, { type });
    },

    getFirstPngUrl(file) {
      const fd = new FormData();
      fd.append("file", file);
      // this.$post为我自己封装的axios的post方法。可直接用axios的post方法
      // this.$axios.post(this.showConText.host, fd).then((res) => {
      //   this.videoFirstimgsrc = res.url;
      //   this.videoWriteFileList[0].url = res.url;
      // });
    },

    getFileName(file) {
      const type = file.raw.type.split("/")[1];
      let name = "";
      if (type) {
        name = file.raw.name.substring(0, file.raw.name.indexOf(`.${type}`));
      }
      return `${name}.png`;
    },
    // handleSuccess(res, file, fileList) {
    // console.log("上传成功了", res, file, fileList);
    // console.log(this.fileList);
    // },

    progressFun() {
      // console.log("正在上传");
    },

    // 图片上传前函数
    beforeUploadFun(file) {
      console.log("上传前函数", file);
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      if (this.upImg.length) {
        // 用户已经不是第一次上传了  判断一定要是图片才可以
        // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
        var fileType = file.type.split("/")[0];
        // let res = imglist.some((item) => {
        //   return item == fileType;
        // });
        // console.log(res, "结果");
        if (fileType != "image") {
          // 上传的不是图片
          // console.log("结果是图片");
          this.$message.info("请继续上传图片");
          this.fileList.pop();
          return false;
        }
      }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });
            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            // this.upImg.push(videaUrl);
            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 标签点击方法
    tagHandle(index, item) {
      if (this.activeTag.includes(index)) {
        // 如果已经点击一次选中了，再次点击是想去掉
        // console.log("arr", arr);
        this.activeTag = this.activeTag.filter((item) => {
          return item != index;
        });
        this.activeIds = this.activeIds.filter((item) => {
          return item != this.tagData[index].id;
        });
      } else {
        // 第一次点击此标签
        this.activeTag.push(index);
        this.activeIds.push(item.id);
      }
      // console.log(this.activeTag, "activeTag");
      // this.activeTag.push(index);
      // this.activeIds.push(item.id);
      // console.log(this.activeTag, "activeTag");
      // console.log(this.activeIds, "activeIds");
    },

    // 标签-列表查询
    tagList() {
      this.$API
        .tagList()
        .then((res) => {
          if (res.code == 1) {
            this.tagData = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //删除图片
    handleRemove(file, fileList) {
      console.log(file.url, "fileurl");
      console.log(this.fileList, "filelist");
      this.fileList.forEach((item, index) => {
        // console.log(item.url);
        if (item.url == file.url) {
          console.log(index);
          this.fileList.splice(index, 1);
          this.upImg = this.upImg.filter((item, delIndex) => {
            return delIndex != index;
          });
          console.log(this.upImg, "upImg");
        }
      });
      this.showAddSource = true;
    },

    //上传图片
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    //   console.log(file);
    // },

    // 改变特殊备注内容按钮选择激活项
    // changeActive(index) {
    //   this.active = index;
    // },

    // 选择文件
    checkFile() {},

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      // console.log(this.fileList, "fileList");
    },

    // 提交
    submitForm(formName) {
      // console.log(this.upImg);
      // return;
      if (!flag) {
        return;
      }

      // 图片上传
      // 检验是否上传图片
      if (this.upImg.length == 0 && this.upVideo.length == 0) {
        // 如果图片数组长度为零并且视频数组不为零
        this.$message({
          message: "请上传图片",
          type: "warning",
        });
        return;
      }
      if (this.upVideo.length == 0 && this.upImg.length == 0) {
        // 如果视频长度为零并且图片长度也为零
        this.$message({
          message: "请上传视频",
          type: "warning",
        });
        return;
      }
      let imgList = [];
      this.upImg.forEach((item) => {
        imgList.push(item.url);
      });

      // 上传图片到oss
      // this.$API
      //   .senMorePic({
      //     files: this.fileList.map((item) => item.url).join(","),
      //   })
      //   .then((res) => {
      //     if (res.code == 1) {
      //     } else {
      //       this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // return;
      let filelistStr = this.upImg.map((item) => item).join(",");
      // console.log(filelistStr, "拼装好的字符串图片地址");
      // return;
      // 表单校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = false;
          //  拼装参数
          // console.log(this.upImg, "上传图片地址");

          // 图片上传参数
          let filelistStr = this.upImg.map((item) => item).join(",");
          // 上传视频参数
          let videolistStr = this.upVideo.map((item) => item).join(",");
          // console.log(filelistStr, "拼装好的字符串图片地址");
          // return;

          // console.log(this.fileList);
          // 将标签id数组转为字符串
          let tagId = this.activeIds.join(",");
          let paramData = {
            fullName: this.ruleForm.name,
            mobile: this.ruleForm.phone,
            describle: this.ruleForm.problem,
            videoUrl: videolistStr,
            imgsUrl: filelistStr,
            tagId,
          };
          // console.log(paramData);
          // return;
          // 调用提交接口
          this.$API
            .repairIntentionApi(paramData)
            .then((res) => {
              if (res.code == 1) {
                // 提交成功，
                setTimeout(() => {
                  flag = true;
                  this.$message({
                    message: "报修成功",
                    type: "success",
                    duration: 1000,
                  });
                  setTimeout(() => {
                    this.$router.back();
                  }, 500);
                }, 800);
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });

          // 提交
          // 图片视频地址

          // let imgVideaUrls = imgList.join(",");
          // let params = {
          //   intention: {
          //     fullName: this.ruleForm.name,
          //     mobile: this.ruleForm.phone,
          //     describle: this.ruleForm.problem,
          //     imgVideaUrls,
          //   },
          // };
          // console.log("params", params);
          // this.$API.toIntent(params).then((res) => {
          //   console.log(res);
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.no-login-repair-contaner {
  .upload {
    height: 80px !important;
  }
  .upload .el-form-item__label {
    margin-right: 20px;
  }
  .img {
    width: 83px;
    height: 83px;
    margin-left: 21px;
  }
  .repair-box {
    min-width: 1200px;
    width: 100%;
    background: #f8f8f8;
    padding-top: 40px;
    padding-bottom: 40px;
    .contaner {
      background: #f8f8f8;
    }
    .nav-box {
      .pos {
        color: #333333;
        font-size: 16px;
      }
    }
  }
  .repair {
    width: 1200px;
    height: 766px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    padding: 40px;
    .title {
      font-size: 22px;
      color: #333333;
      line-height: 30px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
    }
    .form-box {
      margin-top: 40px;
      margin-left: 301px;
    }
  }
  .el-form-item {
    width: 500px;
    height: 40px;
    .el-input {
      width: 240px;
      height: 40px;
      margin-left: 20px;
    }
  }
  .el-form-item__error {
    left: 20px !important;
  }
  .el-textarea__inner {
    width: 400px;
    height: 120px;
    margin-left: 20px;
  }
  .el-form-item__label {
    text-align: left;
  }
  .btn {
    height: auto;
    margin-left: 26px;
    margin-top: 100px;
    button {
      // width: 72px;
      width: auto;
      padding: 0 12px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #f6f7f9;
      border-radius: 28px 28px 28px 28px;
      border: 1px solid #d6d6d6;
      margin-right: 13px;
      &.active {
        background: #ffe5e7;
        color: #ff0015;
        border: 1px solid #ff0015;
      }
    }
  }
  .login {
    width: 220px;
    height: 50px;
    background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
    box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
    border-radius: 8px 8px 8px 8px;
    font-size: 16px;
    color: #ffffff;
    margin-top: 104px;
    margin-left: 62px;
  }
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }
  .el-upload-list__item {
    width: 83px !important;
    height: 83px !important;
  }
  .el-upload-list__item-actions {
    width: 83px !important;
    height: 83px !important;
  }
  .el-upload-list__item-thumbnail {
    width: 83px !important;
    height: 83px !important;
  }
}
</style>
